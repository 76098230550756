import { computed, effect, inject, Injectable } from "@angular/core";
import { toObservable } from "@angular/core/rxjs-interop";
import { gql } from "@apollo/client/core";
import { LoadUserInfoService } from "@auth/services/load-user-info.service";
import { OperationType } from "@models/data/permission";
import { Role } from "@models/data/role";
import { UserInfo } from "@models/data/user-info";
import { logger } from "@shared/dynamic-component/dynamic-component.logger";
import { Apollo } from "apollo-angular";
import { KEYCLOAK_EVENT_SIGNAL, KeycloakEventType, ReadyArgs, typeEventArgs } from "keycloak-angular";
import Keycloak from "keycloak-js";
import { catchError, map, Observable, of, shareReplay, Subject, switchMap, takeUntil, tap } from "rxjs";
import { filter } from "rxjs/operators";
import { environment } from "src/environments/environment";

export const JWT = {
    access_token: "JWT_ACCESS_TOKEN",
    refresh_token: "JWT_REFRESH_TOKEN",
    expiration: "JWT_EXPIRATION",
    refresh_expiration: "JWT_REFRESH_EXPIRATION",
    grantType: {
        key: "grant_type",
        password: "password",
        refreshToken: "refresh_token",
    },
    loginBody: {
        username: "username",
        password: "password",
        refreshToken: "refresh_token",
    },
    logoutBody: {
        client_id: "client_id",
        client_secret: "client_secret",
        refreshToken: "refresh_token",
    },
};

const twentyMinutes: number = 1_000 * 60 * 20;

type AuthRequestResult = {
    access_token: string;
    expires_in: number;
    "not-before-policy": number;
    refresh_expires_in: number;
    refresh_token: string;
    scope: string;
    session_state: string;
    token_type: string;
};

export type UserInfoResult = {
    currentUserInfo: UserInfo;
};

@Injectable({
    providedIn: "root",
})
export class AuthService {
    private readonly apollo = inject(Apollo);
    private readonly loadUserInfoService = inject(LoadUserInfoService);
    private readonly keycloakEvent = inject(KEYCLOAK_EVENT_SIGNAL);
    private readonly keycloakService = inject(Keycloak);

    private readonly disconnectEvent$: Subject<void> = new Subject<void>();
    private readonly loginEvent$: Subject<void> = new Subject<void>();
    private readonly logoutEvent$: Subject<void> = new Subject<void>();

    public readonly keycloakEvent$ = toObservable(this.keycloakEvent);

    private readonly _isLoggedIn = computed(() => {
        const currentKeycloakEvent = this.keycloakEvent();

        if (currentKeycloakEvent.type === KeycloakEventType.Ready) {
            return typeEventArgs<ReadyArgs>(currentKeycloakEvent.args);
        }

        const loggedEvents = [
            KeycloakEventType.AuthSuccess,
            KeycloakEventType.AuthRefreshSuccess,
        ];
        return loggedEvents.includes(currentKeycloakEvent.type);
    });

    private readonly _isLoggedOut = computed(() => !this._isLoggedIn());

    private readonly onKeycloakRefreshToken$ = toObservable(this.keycloakEvent).pipe(
        filter(event => event.type === KeycloakEventType.AuthRefreshSuccess),
    );

    private userInfo$: Observable<UserInfo> = this.keycloakEvent$.pipe(
        tap(() => logger?.debug("Refresh UserInfo")),
        filter(() => this.isLogged()),
        switchMap(() => this.executeGetUserInfo()),
        takeUntil(this.logoutEvent$),
        catchError((err) => {
            console.error(err);
            throw new Error("Failed to get user info");
        }),
        shareReplay(1),
    );

    private readonly _onAccesstokenExpired = effect(() => {
        const currentKeycloakEvent = this.keycloakEvent();
        if (currentKeycloakEvent.type === KeycloakEventType.TokenExpired) {
            this.keycloakService.updateToken(twentyMinutes)
                .then(() => {
                    logger?.debug("Token refreshed");
                })
                .catch(() => {
                    logger?.debug("Failed to refresh token");
                });
        }
    });

    private readonly _onKeycloakEventType = effect(() => {
        const currentKeycloakEvent = this.keycloakEvent();
        if (currentKeycloakEvent.type === KeycloakEventType.AuthRefreshError) {
            this.logout();
            console.error("Token failed to refresh");
            throw new Error("Failed to refresh token");
        }
    });

    private readonly _onRefreshTokenSuccess = effect(() => {
        const currentKeycloakEvent = this.keycloakEvent();
        if (currentKeycloakEvent.type === KeycloakEventType.AuthRefreshSuccess) {
            this.executeGetUserInfo().subscribe({
                next: userInfo => {
                    logger?.debug("Refresh UserInfo", userInfo);

                },
            });
        }
    });

    constructor() {
        this.userInfo$.subscribe({
            next: () => logger?.debug("UserInfo", this.userInfo$),
        });
    }

    logout() {
        this.clearSession();
        this.disconnectEvent$.next();
        this.logoutEvent$.next();
        this.keycloakService.logout({
            redirectUri: environment.keycloak.redirectUrl,
        });
    }

    static parseJWT(token: string): AuthRequestResult {
        const base64Url = token.split(".")[1];
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        const jsonPayload = decodeURIComponent(
            window
                .atob(base64)
                .split("")
                .map((c) => `%${ (`00${ c.charCodeAt(0).toString(16) }`).slice(-2) }`)
                .join(""),
        );

        return JSON.parse(jsonPayload);
    }

    getSessionId(): string | undefined {
        return this.keycloakService.sessionId;
    }

    clearSession(): void {
        this.apollo.client.cache.reset();
        localStorage.clear();
    }

    getRoles(): Observable<Role[]> {
        return this.getUserInfo().pipe(map((userInfo) => userInfo.roles));
    }

    hasRole(roleName: string) {
        return this.getRoles().pipe(
            map((userRoles) => userRoles.some((role) => role.name === roleName)),
        );
    }

    hasRoles(roles: string[]): Observable<boolean> {
        if (roles.length === 0) {
            return of(true);
        }
        return this.getRoles().pipe(
            map((userRoles) =>
                userRoles.some((role) =>
                    roles.some((roleName) => roleName === role.name),
                ),
            ),
        );
    }

    isAdmin(): Observable<boolean> {
        return this.getUserInfo().pipe(
            map((userInfo) =>
                userInfo.roles.some((role) => "Administrator" === role.name),
            ),
        );
    }

    private executeGetUserInfo(): Observable<UserInfo> {
        return this.apollo
        .query<UserInfoResult>({
            query: gql`
                query UserInfo {
                    currentUserInfo {
                        userId
                        userName
                        roles {
                            id
                            name
                            permissions
                        }
                        properties
                    }
                }
            `,
            fetchPolicy: "cache-first",
        })
        .pipe(map((result) => result?.data?.currentUserInfo));
    }

    getUserInfo(): Observable<UserInfo> {
        return this.userInfo$;
    }

    isLogged(): boolean {
        return this._isLoggedIn();
    }

    isLogOut(): boolean {
        return this._isLoggedOut();
    }

    canI(action: OperationType, objectName: string): Observable<boolean> {
        return this.getRoles().pipe(
            map(roles => this.canRoleAccess(roles, action, objectName)),
        );
    }

    getPermissionsOperatorsFor(objectName: string): Observable<OperationType[]> {
        return this.getRoles().pipe(
            map(roles => roles.map(role => role.permissions)
                .filter(permissions => permissions[objectName] !== undefined)
                .map(permissions => permissions[objectName].profileOperations)
                .map(profileOperators => Object.values(profileOperators))
                .flatMap(profileOperators => profileOperators.map(value => value.values))
                .flat(),
            ),
            map(operators => [ ...new Set(operators) ]),
        );
    }

    canRoleAccess(userRoles: Role[], action: "CREATE" | "UPDATE" | "READ" | "DELETE", objectName: string) {
        return userRoles.some((role) => {
            if (!Object.keys(role.permissions).includes(objectName)) {
                return false;
            }
            const permission = role.permissions[objectName];
            const hasPermission = Object.entries(permission.profileOperations).some(([ key, data ]) => key !== "GROUP" && data.values.includes(action));
            console.log(`Has permission to ${ action } on ${ objectName }: ${ hasPermission }`);
            return hasPermission;
        });
    }

    hasPermissions(objectsPermissionsNeeded: string[]): Observable<boolean> {
        return this.getRoles().pipe(
            map((roles) =>
                objectsPermissionsNeeded.every((objectPermissionNeeded) =>
                    this.hasRightsOnObject(roles, objectPermissionNeeded),
                ),
            ),
        );
    }

    hasPermission(objectPermissionNeeded: string): Observable<boolean> {
        return this.hasPermissions([ objectPermissionNeeded ]);
    }

    hasRightsOnObject(userRoles: Role[], objectName: string): boolean {
        return userRoles.some((role) => {
            if (!Object.keys(role.permissions).includes(objectName)) {
                return false;
            }
            const permission = role.permissions[objectName];
            return Object.entries(permission.profileOperations)
                .map(([ _, operation ]) => operation.values)
                .flat()
                .includes("READ");
        });
    }
}
